import { FilterOutlined } from "@ant-design/icons";
import { Empty, Row, Space } from "antd";
import { find } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Breadcrumb,
  ClearRefinements,
  HierarchicalMenu,
  HitsPerPage,
  Pagination,
  Panel,
  Stats,
  connectHits,
} from "react-instantsearch-dom";
import { useMediaQuery } from "react-responsive";

import BlockButton from "../components/BlockButton";
import ProductCard from "../components/ProductCard";
import PriceSlider from "../components/Search/PriceSlider";
import SortBy from "../components/Search/SortBy";
import { useListProducts } from "../utils/graphql";
import styles from "./css/products.module.scss";

const Hits = connectHits(({ hits }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const isDesktop = useMediaQuery({ minWidth: 1224 });

  const { products } = useListProducts();

  const renderProducts = () => {
    return hits.length ? (
      hits
        // Because that the algolia indexes update faster than the website rebuilds (SSR), we simply filter out algolia results that are not yet available from Gatsby by comparing their ID
        .filter((hit) =>
          products.some((product) => product.contentful_id === hit.objectID)
        )
        .map((hit) => {
          // The contentful data is passed throughout the components for UI rendering
          // Therefore Algolia is only used as a search engine
          const product = find(
            products,
            (product) =>
              product.contentful_id === hit.objectID &&
              product.node_locale === language
          );
          // For contentful entries that do not have valid data in the current selected language, we will display an English version as a fallback
          const fallback = find(
            products,
            (product) =>
              product.contentful_id === hit.objectID &&
              product.node_locale === "en"
          );

          return (
            <ProductCard
              key={hit.objectID}
              product={product}
              fallback={fallback}
            />
          );
        })
    ) : (
      <Empty />
    );
  };
  return (
    <>
      {isDesktop ? (
        <Row justify="center">{renderProducts()}</Row>
      ) : (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            width: "100vw",
            flexWrap: "wrap",
          }}
        >
          {renderProducts()}
        </div>
      )}
    </>
  );
});

const Search = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const isMobile = useMediaQuery({ maxWidth: 1224 });

  const [isPanelOpen, setPanelOpen] = useState(false);

  useEffect(() => {
    if (isDesktop) setPanelOpen(true);
    if (isMobile) setPanelOpen(false);
  }, [isDesktop, isMobile]);

  return (
    <Space
      direction="vertical"
      style={{ width: "100%" }}
      className={styles.container}
    >
      {isDesktop && (
        <div className={styles.breadCrumbs}>
          <Breadcrumb
            translations={{
              rootLabel: t("search.rootLabel"),
            }}
            attributes={[
              `hierarchicalCategories.${language}.lv0`,
              `hierarchicalCategories.${language}.lv1`,
              `hierarchicalCategories.${language}.lv2`,
              `hierarchicalCategories.${language}.lv3`,
            ]}
          />
        </div>
      )}
      <Row
        justify="center"
        style={{
          width: "100%",
          position: "fixed",
          left: 0,
          zIndex: 2,
          bottom: 110,
          visibility: isMobile && !isPanelOpen ? "visible" : "hidden",
          pointerEvents: isMobile && !isPanelOpen ? "auto" : "none",
        }}
      >
        <BlockButton onClick={() => setPanelOpen(true)}>
          <FilterOutlined /> {t("search.filters")}
        </BlockButton>
      </Row>
      <div className={styles.wrapper}>
        <aside
          className={styles.panelWrapper}
          style={{
            transform: !isPanelOpen ? "translateY(100%)" : "translateY(0)",
            opacity: !isPanelOpen ? "0" : "1",
            pointerEvents: !isPanelOpen ? "none" : "auto",
            transition: "all 0.3s ease",
          }}
        >
          <div className={styles.panel}>
            <ClearRefinements
              className={styles.clear}
              translations={{
                reset: t("search.clearFilters"),
              }}
            />
          </div>

          <div className={styles.panel}>{isMobile && <SortBy />}</div>

          <Panel header={t("search.category")} className={styles.panel}>
            <HierarchicalMenu
              attributes={[
                `hierarchicalCategories.${language}.lv0`,
                `hierarchicalCategories.${language}.lv1`,
                `hierarchicalCategories.${language}.lv2`,
                `hierarchicalCategories.${language}.lv3`,
              ]}
            />
          </Panel>

          <div style={{ margin: 20 }}>
            <Panel header={t("search.priceRange")}>
              <PriceSlider
                attribute="price"
                defaultRefinement={{
                  min: 0,
                }}
              />
            </Panel>
          </div>
          {isMobile && isPanelOpen && (
            <BlockButton
              htmlType="button"
              style={{
                height: 80,
                position: "fixed",
                zIndex: 1,
                bottom: 0,
              }}
              onClick={() => setPanelOpen(false)}
            >
              {t("search.applyFilters")}
            </BlockButton>
          )}
        </aside>
        <div className={styles.resultsWrapper}>
          {isDesktop && (
            <header
              style={{
                display: isMobile && "table-column",
                visibility: !isMobile ? "visible" : "hidden",
                pointerEvents: !isMobile ? "auto" : "none",
              }}
            >
              <Stats />
              <SortBy />

              <HitsPerPage
                defaultRefinement={12}
                items={[
                  { value: 12, label: "12 results per Page" },
                  { value: 48, label: "48 results per Page" },
                ]}
              />
            </header>
          )}

          <div style={{ position: isMobile && isPanelOpen && "fixed" }}>
            {isMobile && isPanelOpen && (
              <div
                onClick={() => setPanelOpen(false)}
                className={"overlay"}
                style={{
                  width: "100vw",
                  height: "100vh",
                  position: "fixed",
                  zIndex: 1,
                  top: 0,
                  left: 0,
                  opacity: 0.8,
                  background: "black",
                }}
              />
            )}
            <Hits />
            <Pagination
              padding={2}
              showFirst={false}
              showLast={false}
              translations={{
                previous: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.143"
                    >
                      <path d="M9 5H1M5 9L1 5l4-4" />
                    </g>
                  </svg>
                ),
                next: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.143"
                    >
                      <path d="M1 5h8M5 9l4-4-4-4" />
                    </g>
                  </svg>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </Space>
  );
};
export default Search;

export const LIST_LINEITEMS = `
  query($id: String!) {
    allContentfulProduct(filter: {contentful_id: {eq: "$id"}, platform: {eq: "Wet Market"}}) {
      edges {
        node {
          id
          title
          slug
          node_locale
          contentful_id
          seller
          website
          platform
          description {
            description
          }
          images {
            file {
              url
            }
          }
          skus {
            price
            props
            currency
            id
            contentfulid
          }
          categories {
            hierarchyLevel
            title
            slug
          }
        }
      }
    }
  }
`;
