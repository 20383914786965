import React from "react";
import { SortBy } from "react-instantsearch-dom";

import config from "../../config";

const SortByComponent = () => {
  return (
    <SortBy
      defaultRefinement={config.algolia.primaryIndex}
      items={[
        {
          value: config.algolia.primaryIndex,
          label: "Sort by Featured",
        },
        {
          value: `${config.algolia.primaryIndex}_price_asc`,
          label: "Sort by Price asc.",
        },
        {
          value: `${config.algolia.primaryIndex}_price_desc`,
          label: "Sort by Price desc.",
        },
        {
          value: `${config.algolia.primaryIndex}_alphabet_asc`,
          label: "Sort by Alphabetical order asc.",
        },
        {
          value: `${config.algolia.primaryIndex}_alphabet_desc`,
          label: "Sort by Alphabetical order desc.",
        },
      ]}
    />
  );
};

export default SortByComponent;
